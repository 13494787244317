import { render, staticRenderFns } from "./Product004.vue?vue&type=template&id=f079814a&scoped=true&"
import script from "./Product004.vue?vue&type=script&lang=js&"
export * from "./Product004.vue?vue&type=script&lang=js&"
import style0 from "./Product004.vue?vue&type=style&index=0&id=f079814a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f079814a",
  null
  
)

export default component.exports